import {Component, Inject, Injectable, OnInit, PLATFORM_ID} from '@angular/core';
import {Forex} from "../interface/forex";
import {Currency} from "../interface/currency";
import {CurrencyList} from "../model/currency-list";
import {StoreForexService} from "../../service/store-forex.service";
import {RequestMapperService} from "../../../request-mapper.service";
import {ForexTransmissionService} from "../../../raintree/transmission/forex-transmision/forex-transmission.service";
import {JWTService} from "../../../authentication/jwt.service";
import {UpdateForexInProfileService} from "../../service/update-forex-in-profile.service";
import {Customer} from "../../../raintree/interface/customer-profile/customer";
import {GlobalService} from "../../../services/global.service";
import {isPlatformBrowser} from '@angular/common';
import {CurrencyResolverService} from "../../../currency-resolver.service";


@Injectable({
	providedIn: 'root' // This means it's a singleton service provided at the root level
})
@Component({
	selector: 'fb-forex',
	templateUrl: './forex.component.html',
	styleUrls: ['./forex.component.scss']
})
export class ForexComponent implements OnInit {

	public currencyList: Currency[] = [];
	public selectedCurrency: string = '';
	public customerProfile: Customer = {} as Customer
	public isMobileDevice = false;
	public isCheckOutPageActive = false;
	public isBrowser: boolean = false;
	constructor(
		private storeForex: StoreForexService,
		private _forexTransmission: ForexTransmissionService,
		private _jwt: JWTService,
		private _updateForex: UpdateForexInProfileService,
		private _globalService: GlobalService,
		private _currencyResolver: CurrencyResolverService,
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);
	}

	public ngOnInit(): void {
		this.setForex();
		this.selectedCurrency = this._currencyResolver.getSelectedCurrency().toLowerCase();
		this._currencyResolver.selectCurrencyChange.subscribe(() => {
			this.selectedCurrency = this._currencyResolver.getSelectedCurrency().toLowerCase();
		});
		this._globalService.customerProfile.subscribe((customerData: Customer): void => {
			this._updateForex.storeCustomerProfile = customerData;
			this.getUserProfileAndSetForex(customerData)
		});

	}

	public onCurrencyChange(): void {

		this._currencyResolver.onCurrencyChange(this._currencyResolver.convertStringToApCurrency(this.selectedCurrency));

		if (this._jwt.hasValidJWT()) {
			this._updateForex.updateProfileApi(this.selectedCurrency);
		}

	}

	public getUserProfileAndSetForex(customerProfile: Customer): void {
		this.customerProfile = customerProfile;
		this.setForex();
	}

	protected setForex(): void {
		this.currencyList = CurrencyList.getExchangeRate;

		this.currencyList.forEach((currency: Currency): void => {

			if (currency.name === 'gbp') {
				currency.rate = this.storeForex.forexResponse.gbp
			} else if (currency.name === 'usd') {
				currency.rate = this.storeForex.forexResponse.usd
			} else if (currency.name === 'eur') {
				currency.rate = this.storeForex.forexResponse.eur
			}
		});
	}

}


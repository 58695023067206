import {Injectable} from '@angular/core';
import {Customer} from '../raintree/interface/customer-profile/customer';
import {Subject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class GlobalService {

	constructor() { }
	public popupOpenClose:Subject<boolean> = new Subject<boolean>();
	public popupOpenClosePreOrder:Subject<boolean> = new Subject<boolean>();
	public isMobileMenuOpen:Subject<boolean> =  new Subject<boolean>();
	public isShippingAndPaymentActiveInCheckout:Subject<boolean> =  new Subject<boolean>();

	public customerProfileData:Customer = {} as Customer;

	public customerProfile:Subject<Customer> = new Subject<Customer>();

	public isTheOrderInstock:Subject<boolean> = new Subject<boolean>();
	public disableAddToCart:Subject<boolean> = new Subject<boolean>();
	public isAnySizeAvailable:boolean = true;


	public sendCustomerInfo(data: Customer): void {
		this.customerProfile.next(data);
		this.customerProfileData = data;
	}

	public popUpOpenClose(data: boolean): void{
		this.popupOpenClose.next(data);
	}
	public popUpOpenClosePreorder(data: boolean): void{

		this.popupOpenClosePreOrder.next(data);


	}
}

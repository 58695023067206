<div class="outer">
	<div class="craft_wrapper">
		<div class="menu_ind_wrapper">
			<!-- <div class="menu_ind_secondary">
				{{dataFinish.name}}
			</div> -->
			<ng-container *ngFor="let seg of this.dataFinish.category; let i = index">
				<div class="tertiary_menu_inner {{this.dataFinish.category.length<2?'full':''}}">
					<div class="tertiary_ind">
						<span class="tertiary_heading" (click)="navigateCategoryRoute('products/finished',seg)">{{seg.segmentCategoryName}}</span>
						<ng-container *ngFor="let sub of seg.segmentCategory; let o = index">
							<a
								(click)="navigate('products/finished',
								seg.segmentCategoryName, sub.subCategoryName)">
								<button class="tertiary_menu {{sub.hoverActive?'hover_active':''}}"
										(mouseenter)="hoverUpdateImage(sub.image,i,o)"
										(mouseleave)="hoverleaveImage(seg.segmentCategory[o].image)">
									{{sub.subCategoryName}}
								</button>
							</a>
						</ng-container>
					</div>
				</div>
			</ng-container>

		</div>
	</div>
<!--	<div class="image_container_for_nav">-->
<!--		&lt;!&ndash; <img src="{{hoveredImage}}" /> &ndash;&gt;-->
<!--		<div class="bg_image_image_container" [ngStyle]="{'background': 'url(' + hoveredImage + ') no-repeat 0 0 / cover'}">-->

<!--			&lt;!&ndash; </div> &ndash;&gt;-->
<!--		</div>-->
<!--	</div>-->

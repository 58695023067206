import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {LeftSideMenu} from '../interface/left-side-menu';
import {RightSideMenu} from '../interface/right--side-menu';
import {GlobalService} from 'src/app/services/global.service';
import {Customer} from 'src/app/raintree/interface/customer-profile/customer';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AuthService} from '@auth0/auth0-angular';
import {JWTService} from 'src/app/authentication/jwt.service';
import {RequestMapperService} from 'src/app/request-mapper.service';
import {ToastMessageService} from 'src/app/services/toast-message.service';
import {Router} from '@angular/router';
import {Cart} from 'src/app/cart/interface/cart';
import {GetAndSetCartDataInLocalstorageService} from 'src/app/cart/service/set-cart-data-in-localstorage.service';
import {CartTransmissionService} from 'src/app/raintree/transmission/cart/cart-transmission.service';
import {RaintreeResponse} from 'src/app/raintree/interface/raintree-response';
import {GetCart} from 'src/app/cart/interface/cart-get';
import {PrepareGetCartService} from 'src/app/cart/service/prepare-get-cart.service';
import {CountryList} from "../../../address/interface/countryList";
import {CheckoutPrepareCartResponseService} from 'src/app/checkout/service/checkout-prepare-cart-response.service';
import {CartCalculationService} from 'src/app/cart/service/cart-calculation.service';
import {isPlatformBrowser} from '@angular/common';
import {WishlistService} from "../../../wishlist/wishlist.service";

@UntilDestroy()
@Component({
	selector: 'fb-static-top-navigation',
	templateUrl: './static-top-navigation.component.html',
	styleUrls: ['./static-top-navigation.component.scss']
})
export class StaticTopNavigationComponent implements OnInit {

	@Input() dataStaticLeftMenu: LeftSideMenu[] = [];
	@Input() dataStaticRightMenu: RightSideMenu[] = [];
	public userName = '';
	public isLoggedIn = false;
	public openCartPop = false;
	public cartCount: number = 0;
	public wishlistCount: number = 0;
	public fetchedCartData: Cart[] = []
	public isMobileMenuOpen: boolean = false;
	public countryList: CountryList[] = [];
	public isBrowser: boolean = false;
	public anuprernaBlogLink = '/content/';

	constructor(
		private _globalService: GlobalService,
		public authService: AuthService,
		private _jwt: JWTService,
		public _toast: ToastMessageService,
		public globalService: GlobalService,
		private _router: Router,
		private _getSetCartInLocal: GetAndSetCartDataInLocalstorageService,
		private _cartTransmission: CartTransmissionService,
		private _wishlist: WishlistService,
		private _prepareCartResponse: PrepareGetCartService,
		private _prepareCheckoutCartResponse: CheckoutPrepareCartResponseService,
		private _cartCalculation: CartCalculationService,
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);

		this._getSetCartInLocal.openCartModal
			.pipe(untilDestroyed(this))
			.subscribe((isOpen: boolean): void => {

			this.openCartPop = isOpen;
			if (this._jwt.hasValidJWT()) {
				if (isOpen) {
					this.getCartData();
				}
			}
		});

	}

	public ngOnInit(): void {
		this._globalService.customerProfile
			.pipe(untilDestroyed(this))
			.subscribe((res: Customer): void => {
			// console.log(res);
			// debugger
			this.dataStaticRightMenu.forEach((elm: RightSideMenu): void => {
				let firstName = res.tenant.name.split(" ")[0];
				if (elm.icon == 'person') {
					elm.text = firstName;
					elm.link = RequestMapperService.PROFILE
				}
			})
			this.isLoggedIn = true;
			if(this.isBrowser) {
				localStorage.removeItem("cartData"); //TODO: remove (beasuse of first timer login,after adding cart)
			}

			this.getCartData(true);

		});

		this.wishlistCount = this._wishlist.getWishlistCSV().split(',')[0] === ''? 0 :
			this._wishlist.getWishlistCSV().split(',').length;

		this._wishlist.wishlistItems
			.pipe(untilDestroyed(this))
			.subscribe(() => {
			this.wishlistCount = this._wishlist.getWishlistCSV().split(',')[0] === ''? 0 :
				this._wishlist.getWishlistCSV().split(',').length;
		});

		setTimeout((): void => {
			this._getSetCartInLocal.getCartFromLocal();
		}, 0);

		this._getSetCartInLocal.cartResponse
			.pipe(untilDestroyed(this))
			.subscribe((data: Cart[]) => {
			// debugger
			this.fetchedCartData = data;
			this.cartCount = this.fetchedCartData ? this.fetchedCartData.length : 0;
		});
		if (this.isBrowser) {

			if (window.innerWidth < 1200) {
				this._globalService.isMobileMenuOpen.subscribe((isMenuOpen: boolean): void => {
					this.isMobileMenuOpen = isMenuOpen;
				});
			}
		}

		// setTimeout(()=> this.isForexLoaded = true,1000)
	}

	public logout(isLogout: string): void {
		if (isLogout != 'logout') return
		this._jwt.destroySession();

		if (this.isBrowser) {
			this.authService.logout({
				logoutParams: {
					returnTo: this.isBrowser? window.location.origin :''
				}
			}).subscribe(() => {
				location.href = RequestMapperService.BASE_RELATIVE_URL;
				if(this.isBrowser) {
					localStorage.removeItem("cartData");
				}

			});
		}
	}

	public rightMenuAction(str: string): void {
		// debugger
		if (str.toLocaleLowerCase() === 'cart') {
			if (this.cartCount === 0) {
				this._toast.error("Cart is empty", "Your Cart is empty. Add item to your cart and try again", 'bottom-center');
				return;
			}
			this.openCartPop = true;
			if (this._jwt.hasValidJWT()) {
				this.getCartData();

			}

		}

	}

	public cartLengthUpdate(evt: number) {
		evt = this.cartCount
	}

	public addCartData(data: Cart): void {

		// console.log(data)
		this._cartTransmission.AddCartItem(
			RequestMapperService.ADD_CART_ITEM,
			data,
			() => {
			},
			() => {
			},
			(response: RaintreeResponse) => {
				this._toast.success(response.message, response.message, 'bottom-center');
				// localStorage.removeItem("cartData");
			},
			(error: string) => {
				// console.log("TODO add toaster: custom message: after login cart a add hoche na")
			},
			() => {
			}
		)
	}

	public getCartData(onload?: boolean): void {
		// debugger
		this._cartTransmission.getCartItem(
			RequestMapperService.GET_CART_ITEM,
			() => {
			},
			() => {
			},
			(response: GetCart[]) => {
				if (response.length > 0) {
					setTimeout(() => {
						this._getSetCartInLocal.cartResponse.next(response)
						this.fetchedCartData = this._prepareCartResponse.prepareCartList(response);
						this.fetchedCartData =  this._prepareCheckoutCartResponse.prepareCheckoutList(response);
						this.cartCount = response.length
						this.setCartDataInLocal(this.fetchedCartData);

						if(onload) {
							this._cartCalculation.selectedFinishForCartCalc = this._cartCalculation.zeroElementFinish
							this._getSetCartInLocal.openCartModal.next(false); // DNR: this is for finish component reload
						}

					}, 0);
				}
			},
			() => {
				this.afterLoginAddCartDataFromLocal();
			},
			() => {
			}
		)
	}

	public afterLoginAddCartDataFromLocal(): void {
		if (this.isLoggedIn && this.isBrowser && localStorage.getItem("cartData")) {
			let localCartResponse: Cart[] = [...JSON.parse(localStorage.getItem("cartData")!)];
			localCartResponse.forEach((cartData: Cart) => {
				this.addCartData(cartData)
			})
		}
	}

	public setCartDataInLocal(data: Cart[]) {
		if(this.isBrowser) {
			localStorage.removeItem("cartData");
			localStorage.setItem("cartData", JSON.stringify(data))
		}

	}

	public refreshCartList(): void {
		this.getCartData();
	}

	public mobileMenuOpenClose(isMobileMenuOpen: boolean): void {
		this.isMobileMenuOpen = !isMobileMenuOpen;
		this.globalService.isMobileMenuOpen.next(this.isMobileMenuOpen);
	}

	public closeMobileMenu(): void {
		this._globalService.isMobileMenuOpen.next(false);
	}


	public storePreviousUrl(text: string) {
		if(text === 'Login' && this.isBrowser) {
			localStorage.setItem("storeUrl", window.location.href);
		}
	}
}

<div class="outer">
	<div class="craft_wrapper">
		<div class="menu_ind_wrapper">
			<!-- <div class="menu_ind_secondary">
				{{dataFinish.name}}
			</div> -->
			<ng-container *ngFor="let seg of this.dataFinish.category; let i = index">
				<div class="tertiary_menu_inner {{this.dataFinish.category.length<2?'full':''}}">
					<div class="tertiary_ind">
						<a class="new_url_for_fabric" [href]="generateCategoryRedirectionLink('products/finished', seg)">
						<span class="tertiary_heading"
							>{{seg.segmentCategoryName}}</span>
						</a>
						<ng-container *ngFor="let sub of seg.segmentCategory; let o = index">
							<!-- <a class="new_url" href="products/finished?segment-{{seg.segmentCategoryName}}={{sub.subCategoryName}}"
							 > -->
							 <a class="new_url" [href]="generateSegmentRedirectionLink('products/finished',seg.segmentCategoryName,sub.subCategoryName)"
							 >
								<button class="tertiary_menu {{sub.hoverActive?'hover_active':''}}"
									(mouseenter)="hoverUpdateImage(sub, sub.image,i,o)"
									(mouseleave)="hoverleaveImage(seg.segmentCategory[o].image)">
									{{sub.subCategoryName}}
								</button>
							</a>
						</ng-container>
					</div>
				</div>
			</ng-container>

		</div>
	</div>
	<div class="image_container_for_nav">
		<a href="/product/finished-product/{{hoveredImageSubCategory.slug}}">
			<div class="bg_image_image_container"
				[ngStyle]="{'background': 'url(' + hoveredImage + ') no-repeat 0 0 / cover'}">
			</div>
		</a>
	</div>
</div>

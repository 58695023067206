import {Injectable} from '@angular/core';
import {ConfigurationService} from './services/configuration.service';

@Injectable({
	providedIn: 'root'
})
export class RequestMapperService {

	public static readonly BASE_RELATIVE_URL: string = '';
	public static readonly ROOT_URL: string = '/';
	public static readonly DEMO_URL: string = 'demo';
	public static readonly UI_URL: string = 'ui';
	public static readonly CHECKOUT: string = 'checkout';
	// public static readonly PRODUCT: string = 'product';
	public static readonly STORIES: string = 'stories';
	public static readonly STORYBOARD: string = 'storyboard';
	public static readonly CRAFTS: string = 'crafts';
	public static readonly COLLABORATION: string = 'collaborations';
	public static readonly CLUSTERS: string = 'clusters';
	public static readonly PROFILE: string = 'profile';
	public static readonly PRODUCT_REDIRECTION: string = 'Fabrics/:slug/:segment';
	public static readonly HOME: string = 'home';
	public static readonly PROFILE_ADDRESS: string = 'address';
	public static readonly PROFILE_ORDER: string = 'order';
	public static readonly THANK_YOU_URL: string = 'thank-you';
	public static readonly PROFILEACCOUNT: string = 'account';
	public static readonly PROFILE_DASHBOARD: string = 'dashboard';
	public static readonly ANUPRERNA_BLOG_URL: string = 'https://blog.anuprerna.com/blog-details/';
	public static readonly CUSTOM_CONTENT_URL: string = 'content';
	public static readonly SEARCH_RESULTS_URL: string = 'display';
	public static readonly SEARCH_URL: string = 'search';
	public static readonly FABRIC_URL: string = 'fabric';
	public static readonly FINISHED_URL: string = 'finished';
	public static readonly FILTER_URL: string = 'filter';

	public static readonly GET_PREVIEW_CONTENT_LIST: string = 'home/section';


	// Review
	public static readonly REVIEW: string = 'review';

	// storyboard details
	public static readonly SB_DETAILS: string = 'details';
	public static readonly NOT_FOUND_URL: string = '404';

	//stories
	public static readonly STORY_LISTING: string = 'story';
	public static readonly STORY_DETAILS: string = 'story-details';


	//product
	public static readonly PRODUCT: string = 'products';


	//search
	//search
	public static readonly SEARCH_PATH: string = 'display';

	//product details
	public static readonly PRODUCT_DETAILS: string = 'product';
	public static readonly WISHLIST: string = 'wishlist';

	// contact
	public static readonly CONTACT: string = 'contact';
	public static readonly AUTH: string = 'auth';
	public static readonly LOGIN: string = 'login';
	public static readonly SIGNUP: string = 'signup';
	public static readonly FORGOT_PASSWORD: string = 'forget-password';
	public static readonly FORGET_PASSWORD: string = 'forget-password';
	public static readonly VERIFY_EMAIL: string = 'email-verification';

	constructor() {
	}


	//font awesome link
	public static readonly FONT_AWESOME: string = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css';
	//api
	public static readonly CONFIRM_EMAIL_URL: string =  ConfigurationService.SERVER_ENDPOINT + '/confirm/verification/email';
	public static readonly AUTHENTICATION_URL: string =  ConfigurationService.SERVER_ENDPOINT + '/authenticate';
	public static readonly AUTHENTICATION_EMAIL_URL: string =  ConfigurationService.SERVER_ENDPOINT + '/authenticate/email';
	public static readonly AUTHENTICATION_SOCIAL_URL: string =  ConfigurationService.SERVER_ENDPOINT + '/authenticate/social';
	public static readonly GET_STORY_LIST: string = ConfigurationService.SERVER_ENDPOINT + '/get/story-content-list';
	public static readonly GET_STORY_LIST_CSV: string = ConfigurationService.SERVER_ENDPOINT + '/get/story-content-list/csv/';
	public static readonly GET_STORY_CONTENT: string = ConfigurationService.SERVER_ENDPOINT + '/get/story-content/';
	public static readonly GET_BLOG_LIST: string = ConfigurationService.SERVER_ENDPOINT + '/get/blog-content-list/customer';
	public static readonly GET_BLOG_CONTENT: string = ConfigurationService.SERVER_ENDPOINT + '/get/blog-content/';

	//filter
	public static readonly GET_FILTER: string = ConfigurationService.SERVER_ENDPOINT + '/get/filter/fabric';
	public static readonly GET_FILTER_FINISHED: string = ConfigurationService.SERVER_ENDPOINT + '/get/filter/finished';
	public static readonly GET_FILTER_SEO: string = ConfigurationService.SERVER_ENDPOINT + '/get/filter-seo/';
	public static readonly GET_FILTER_RELATED: string = ConfigurationService.SERVER_ENDPOINT + '/get/related-products/id/';
	public static readonly GET_COLOR_LIST: string = ConfigurationService.SERVER_ENDPOINT + '/get/color-list';
	public static readonly GET_MATERIAL_LIST: string = ConfigurationService.SERVER_ENDPOINT + '/get/material-list';
	public static readonly GET_PATTERN_LIST: string = ConfigurationService.SERVER_ENDPOINT + '/get/pattern-list';

	// product listing
	public static readonly GET_PRODUCT_LISTING_FABRIC: string = ConfigurationService.SERVER_ENDPOINT + '/get/fabric-preview-list';
	public static readonly GET_PRODUCT_LISTING_FINISHED: string = ConfigurationService.SERVER_ENDPOINT + '/get/finished-preview-list';
	public static readonly GET_PRODUCT_DETAILS_FABRIC: string = ConfigurationService.SERVER_ENDPOINT + '/get/fabric-product/slug/';
	public static readonly GET_PRODUCT_DETAILS_FINISH: string = ConfigurationService.SERVER_ENDPOINT + '/get/finished-product/slug/';
	public static readonly GET_RELATED_STORY_PRODUCT: string = ConfigurationService.SERVER_ENDPOINT + '/get/product/related/story/';
	public static readonly GET_RELATED_STORY_IN_PRODUCT_DETAILS: string = ConfigurationService.SERVER_ENDPOINT + '/get/story/related/product/';

	//search
	public static readonly SEARCH: string = ConfigurationService.SERVER_ENDPOINT + '/get/search/result/';

	//auth

	public static readonly POST_SIGNUP: string = ConfigurationService.SERVER_ENDPOINT + '/customer/registration';
	public static readonly REGISTER_EMAIL: string = ConfigurationService.SERVER_ENDPOINT + '/customer/registration/email';
	public static readonly REGISTER_SOCIAL: string = ConfigurationService.SERVER_ENDPOINT + '/customer/registration/social';

	//customer info
	public static readonly CUSTOMER_PROFILE: string = ConfigurationService.SERVER_ENDPOINT + '/get/customer/profile';
	public static readonly UPDATE_CUSTOMER_PROFILE: string = ConfigurationService.SERVER_ENDPOINT + '/update/customer/profile';

	//address
	public static readonly GET_ADDRESS_LIST: string = ConfigurationService.SERVER_ENDPOINT + '/get/address-list';
	public static readonly ADD_ADDRESS: string = ConfigurationService.SERVER_ENDPOINT + '/add/address';
	public static readonly UPDATE_ADDRESS: string = ConfigurationService.SERVER_ENDPOINT + '/update/address';
	public static readonly COUNTRY_LIST: string = 'https://anuprerna-bloomscorp.s3.ap-south-1.amazonaws.com/static-data/country_metadata.json';
	public static readonly DELETE_ADDRESS: string = ConfigurationService.SERVER_ENDPOINT + '/delete/address/';

	// cart api

	public static readonly ADD_CART_ITEM: string = ConfigurationService.SERVER_ENDPOINT + '/add/cart-item';
	public static readonly GET_CART_ITEM: string = ConfigurationService.SERVER_ENDPOINT + '/get/cart-item/list';
	public static readonly UPDATE_CART_ITEM: string = ConfigurationService.SERVER_ENDPOINT + '/update/cart-item';
	public static readonly DELETE_CART_ITEM: string = ConfigurationService.SERVER_ENDPOINT + '/delete/cart-item/';
	public static readonly DELETE_ALL_CART_ITEM: string = ConfigurationService.SERVER_ENDPOINT + '/delete/all-cart-item';

	//shipment api

	public static readonly GET_SHIPMENT: string = ConfigurationService.SERVER_ENDPOINT + '/get/shipment-list';

	//setting api
	public static readonly GET_SETTINGS: string = ConfigurationService.SERVER_ENDPOINT + '/get/settings-list';


	//order api
	public static readonly ADD_OUT_OF_STOCK: string = ConfigurationService.SERVER_ENDPOINT + '/add/inventory-restock-request';
	public static readonly UPDATE_OUT_OF_STOCK_QUANTITY: string = ConfigurationService.SERVER_ENDPOINT + '/update/inventory-restock-request/quantity';


	public static readonly ADD_ORDER_FEEDBACK: string = ConfigurationService.SERVER_ENDPOINT + '/add/order/feedback';
	public static readonly UPDATE_ORDER_FEEDBACK_Q1: string = ConfigurationService.SERVER_ENDPOINT + '/update/order/feedback/q1';
	public static readonly UPDATE_ORDER_FEEDBACK_Q2: string = ConfigurationService.SERVER_ENDPOINT + '/update/order/feedback/q2';
	public static readonly UPDATE_ORDER_FEEDBACK_Q3: string = ConfigurationService.SERVER_ENDPOINT + '/update/order/feedback/q3';
	public static readonly GET_ORDER_FEEDBACK_BY_ORDER: string = ConfigurationService.SERVER_ENDPOINT + '/get/order/feedback/'

	public static readonly GET_PRODUCT_BY_CSV: string = ConfigurationService.SERVER_ENDPOINT + '/get/product-preview-list/csv/';


	public static readonly ADD_ORDER: string = ConfigurationService.SERVER_ENDPOINT + '/add/order';
	public static readonly GET_BY_ID_ORDER: string = ConfigurationService.SERVER_ENDPOINT + '/get/customer/order/';
	public static readonly ORDER_LIST: string = ConfigurationService.SERVER_ENDPOINT + '/get/customer/order-list';
	public static readonly CANCEL_ORDER: string = ConfigurationService.SERVER_ENDPOINT + '/cancel/order';
	//payment session api
	public static readonly CREATE_PAYMENT_SESSION: string = ConfigurationService.SERVER_ENDPOINT + '/create/payment-session';


	//payment success and fail api
	public static readonly PAYMENT_SUCCESS: string = ConfigurationService.SERVER_ENDPOINT + '/update/payment/success';
	public static readonly PAYMENT_FAIL: string = ConfigurationService.SERVER_ENDPOINT + '/update/payment/failure';


	//Discount
	public static readonly GET_DISCOUNT: string = ConfigurationService.SERVER_ENDPOINT + '/get/discount-list';

	//coupon
	public static readonly ADD_COUPON: string = ConfigurationService.SERVER_ENDPOINT + '/apply/voucher/discount';

	//verify tenant


	//verify email
	public static readonly VERIFY_TENANT: string = ConfigurationService.SERVER_ENDPOINT + '/check-email/tenant';
	public static readonly VERIFY_TENANT_PROVIDER: string = ConfigurationService.SERVER_ENDPOINT + '/validate/provider';
	public static readonly SEND_EMAIL: string = ConfigurationService.SERVER_ENDPOINT + '/send/verification/email';
	public static readonly VERIFY_TOKEN: string = ConfigurationService.SERVER_ENDPOINT + "/confirm/verification/email";
	public static readonly SEND_PASSWORD_RESET_EMAIL: string = ConfigurationService.SERVER_ENDPOINT + "/send/password-reset/email";
	public static readonly PASSWORD_RESET: string = ConfigurationService.SERVER_ENDPOINT + "/reset/password";

	//nav get
	public static readonly GET_NAV: string = ConfigurationService.SERVER_ENDPOINT + "/get/navigation";


	//forex
	public static readonly FOREX: string = ConfigurationService.SERVER_ENDPOINT + "/get/forex-exchange-rate/latest";
	public static readonly FOREX_LIST: string = ConfigurationService.SERVER_ENDPOINT + "/get/forex-list";

	//ip
	public static readonly IP: string = ConfigurationService.SERVER_ENDPOINT + "/get/ip-wise/currency";

	// upload image
	public static readonly UPLOAD_IMAGE: string = ConfigurationService.SERVER_ENDPOINT + "/upload/image";

	//contact

	public static readonly CONTACT_API: string = ConfigurationService.SERVER_ENDPOINT + "/send/contact-us";

	//product redirection
	public static readonly PRODUCT_REDIRECTION_REQUEST: string = ConfigurationService.SERVER_ENDPOINT + "/redirect/product";
	public static readonly STORY_REDIRECTION_REQUEST: string = ConfigurationService.SERVER_ENDPOINT + "/redirect/story";
	public static readonly BLOG_REDIRECTION_REQUEST: string = ConfigurationService.SERVER_ENDPOINT + "/redirect/blog";

	public static readonly CDN_HOME: string = "https://anuprerna-bloomscorp.s3.ap-south-1.amazonaws.com/home/";

	// Review
	public static readonly GET_REVIEW: string = ConfigurationService.SERVER_ENDPOINT + "/get/review";
	public static readonly GET_REVIEW_LIST: string = ConfigurationService.SERVER_ENDPOINT + "/get/customer/review";
	public static readonly GET_PRODUCT_REVIEW_LIST: string = ConfigurationService.SERVER_ENDPOINT + "/get/product/review";
	public static readonly GET_REVIEW_STATS: string = ConfigurationService.SERVER_ENDPOINT + "/get/review/stats"
	public static readonly ADD_REVIEW: string = ConfigurationService.SERVER_ENDPOINT + "/add/review";
	public static readonly UPDATE_REVIEW: string = ConfigurationService.SERVER_ENDPOINT + "/update/customer/review";
}

import {Component, Input, OnInit} from '@angular/core';
import {StoryPreview} from "../../story/interface/story-preview";
import {ProductPreview} from "../../product/product-listing/interface/product-preview";
import {WishlistTransmissionService} from "../../raintree/transmission/wishlist-transmission.service";
import {OwlOptions} from "ngx-owl-carousel-o";
import {OwlMultiSliderConfigService} from "../service/owl-multi-slider-config.service";
import {StoryTransmissionService} from "../../raintree/transmission/story-transmission.service";
import {HomeBlogViewService} from "../service/home-blog-view.service";

@Component({
  selector: 'fb-home-blog-view-mobile',
  templateUrl: './home-blog-view-mobile.component.html',
  styleUrls: ['./home-blog-view-mobile.component.scss', '../home-blog-view/home-blog-view.component.scss']
})
export class HomeBlogViewMobileComponent implements OnInit {

	@Input()
	public storyPreview: StoryPreview[] = [];
	@Input()
	public storyContentType: string = '';
	@Input()
	public products: ProductPreview[] = [];
	@Input()
	public loading: boolean = true;
	public customOptions: OwlOptions = OwlMultiSliderConfigService.owlOptions;

	constructor(
		public blogView: HomeBlogViewService
	) {
	}

	ngOnInit() {
	}

}

import {Injectable} from '@angular/core';
import {TransmissionService} from '../transmission.service';
import {HttpClient} from '@angular/common/http';
import {JWTService} from 'src/app/authentication/jwt.service';
import {PasteboxService} from 'src/app/services/pastebox.service';
import {HttpErrorHandlerService} from '../../http-error-handler.service';
import {RaintreeResponse} from '../../interface/raintree-response';
import {Cart} from 'src/app/cart/interface/cart';
import {GetCartResponse} from '../../interface/get-cart-response/get-cart-response';
import {GetCart} from 'src/app/cart/interface/cart-get';

@Injectable({
  providedIn: 'root'
})
export class CartTransmissionService extends TransmissionService {

	constructor(
		_http: HttpClient,
		_httpErrorHandler: HttpErrorHandlerService,
		_jwt: JWTService,
        _pastebox: PasteboxService
	) {
		super(_http, _httpErrorHandler, _jwt, _pastebox);
	}

	public AddCartItem(
		url: string,
		payload: Cart,
		onPreExecute: () => void,
		onPostExecute: (response: RaintreeResponse) => void,
		onSuccess: (response: RaintreeResponse) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	) {

		this.executePostPayload(
			url,
			payload,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			true
		);
	}

	public updateCartItem(
		url: string,
		payload: Cart,
		onPreExecute: () => void,
		onPostExecute: (response: RaintreeResponse) => void,
		onSuccess: (response: RaintreeResponse) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	) {

		this.executePatchPayload(
			url,
			payload,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			true
		);
	}

	public deleteCartItem(
		url: string,
		payload: Cart,
		onPreExecute: () => void,
		onPostExecute: (response: RaintreeResponse) => void,
		onSuccess: (response: RaintreeResponse) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	) {

		this.executeDelete(
			url,
			payload,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			true
		);
	}

	public deleteAllCartItem(
		url: string,
		payload: Cart,
		onPreExecute: () => void,
		onPostExecute: (response: RaintreeResponse) => void,
		onSuccess: (response: RaintreeResponse) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	) {

		this.executeDelete(
			url,
			payload,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			true
		);
	}


	public getCartItem(
		url: string,
		onPreExecute: () => void,
		onPostExecute: (response: GetCart[]) => void,
		onSuccess: (response: GetCart[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	) {

		this.executeGetPayload<GetCartResponse, GetCart[]>(
			url,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			'cartItemList',
			true
		);
	}

}

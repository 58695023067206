import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {MoldedContent} from "../../desktop-navigation/interface/molding/molded-content";
import {Router} from "@angular/router";
import {isPlatformBrowser} from '@angular/common';
import {MoldedContentSegmantCategory} from "../../desktop-navigation/interface/molding/molded-content-segment-category";

@Component({
	selector: 'fb-finish-nav-section-mobile',
	templateUrl: './finish-nav-section-mobile.component.html',
	styleUrls: ['./finish-nav-section-mobile.component.scss']
})
export class FinishNavSectionMobileComponent {
	@Input() dataFinish: MoldedContent = {} as MoldedContent;
	public hoveredImage = '';
	public isBrowser: boolean = false;
	constructor(
		private _router: Router,
		@Inject(PLATFORM_ID) private _platformId: Object
	) {

		this.isBrowser = isPlatformBrowser(this._platformId);
	}

	private _prepareUrl(param: string): string {
		if(param.includes('-'))
			return param.toLowerCase().replace(/\s+/g, '');
		return param.toLowerCase().replace(/\s+/g, '-');
	}

	public ngOnChanges(): void {
		// debugger
		this.dataFinish.category[0].segmentCategory[0].hoverActive = true;
		this.hoveredImage = this.dataFinish.category[0].segmentCategory[0].image;
	}

	public hoverUpdateImage(image: string, segIndex: number, subIndex: number): void {
		this.hoveredImage = image;
		this.dataFinish.category[0].segmentCategory[0].hoverActive = false;
	}

	public hoverleaveImage(image: string): void {
		this.hoveredImage = image;
		this.dataFinish.category[0].segmentCategory[0].hoverActive = true;
	}

	public navigateCategoryRoute(
		productTypeLink: string,
		segment: MoldedContentSegmantCategory
	): void {
		const segments: string[] = segment.segmentCategory
			.map((sub) => sub.subCategoryName);
		this.navigate(
			productTypeLink,
			segment.segmentCategoryName,
			segments.join()
		);
	}

	public getRouterParams(key: string, value: string) {
		return {[this._prepareUrl(key)]: this._prepareUrl(value)};
	}

	public navigate(
		productTypeLink: string,
		paramKey: string,
		paramValue: string
	): void {

		this._router.navigate([productTypeLink,],
			{queryParams: {[this._prepareUrl(paramKey)]: this._prepareUrl(paramValue)}})
			.then((): void => {
				if (this.isBrowser) {
					window.location.reload();
				}

			});
	}
}
